import { LocalJWTManager } from './LocalJWTManager'

const jwtManager = new LocalJWTManager()

export interface DestroyErrorResponse extends Response {
  message?: string
}

function securedFetch(url: string, init: RequestInit = {}) {
  const jwtHeader = jwtManager.encodedToken
    ? { Authorization: `Bearer ${jwtManager.encodedToken}` }
    : null
  if (jwtHeader) {
    init.headers = { ...(init.headers || {}), ...jwtHeader }
  }
  return fetch(url, init)
}

export const checkResponse = (
  response: DestroyErrorResponse
): Promise<DestroyErrorResponse> => {
  if (response.redirected && !response.ok) {
    //TODO: (mlee) where can this be redirected to?
    return fetch(response.url).then(checkResponse)
  }

  if (!response.ok) {
    response.message = `Error while executing request: ${response.url}. Status: ${response.status}`
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}

const toJson = (response: Response) =>
  checkResponse(response).then((response) => response.json())

export interface DestroyValues {
  repo_name: string,
  service_name: string,
  revision: number,
  deployment_number: number,
  force_destroy: boolean
}

export const destroyAction = (values: DestroyValues) =>
  // TODO: Add appropriate URL here
  securedFetch(`/v1/destroy/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(values)
  }).then(toJson)
