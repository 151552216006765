import { App } from '../packages/DestroyForm/app/App'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-dobby-spa',
  portalContainers: ['banquetPortalsContainer'],
  // @ts-ignore
  rootComponent: App
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
