import React from 'react'
import cx from 'classnames'
import {
  TextInputField,
  TextInputFieldProps
} from '@toasttab/buffet-pui-forms'

const BaseConfigField = (props: TextInputFieldProps) => (
  <TextInputField
    {...props}
    containerClassName={cx('md:w-1/2', props.containerClassName)}
  />
)

export const RepoNameField = () => (
  <BaseConfigField
    name='repo_name'
    label='Repository Name'
    placeholder='toast-drones'
    helperText={
    <div className='md:whitespace-nowrap'>
      If you are attempting to destroy a toastweb deployment, please use&nbsp;
      <a
        className='inline-link'
        href='https://jenkins.build.int.toasttab.com/job/toastweb/job/destroy/job/preproduction-toastweb-web/build?delay=0sec'
        target='_blank'
        rel='noreferrer'
      >
        this form
      </a>
      .
    </div>
    }
  />
)

// Service Name
export const ServiceNameField = () => (
  <BaseConfigField
    name='service_name'
    label='Service Name'
    placeholder='drones'
  />
)

// Revision
export const RevisionField = () => (
  <BaseConfigField name='revision' label='Revision' placeholder='1' />
)

// Deployment number
export const DeploymentNumberField = () => (
  <BaseConfigField
    name='deployment_number'
    label='Deployment Number'
    placeholder='1'
  />
)
