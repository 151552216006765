import * as yup from 'yup'

export const validationSchema = yup.object().shape({
    service_name: yup
        .string()
        .matches(/^[a-z\-0-9]+$/, 'Service name must be all lower case.')
        .required('This field is required'),
    repo_name: yup
        .string()
        .matches(/^(?!toastweb$).*/, 'Destroys for toastweb deployments are handled by the destroy job within Jenkins.')
        .required('This field is required'),
    revision: yup
        .number()
        .required('This field is required'),
    deployment_number: yup
        .number()
        .required('This field is required')
})
