import React from 'react'

import { Button } from '@toasttab/buffet-pui-buttons'
import {
  Modal,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'
import {DestroyValues} from "../app/queries";

export const DestroyConfirmationModal = ({isModalOpen, closeModal, doDestroy, destroyParams}: { isModalOpen: boolean, closeModal: any, doDestroy: any, destroyParams: DestroyValues }) => {
  const closeAndDoDestroy = () => {
    doDestroy(destroyParams)
    closeModal()
  }

  return (
    <div className='h-screen w-screen'>
      <Modal
        onRequestClose={closeModal}
        isOpen={isModalOpen}
      >
        <ModalBody>
          <div className='relative pt-1 pb-1'>
            <div className='type-headline-5 italic'>
              This is the last active revision of the service. Are you sure you want to destroy it?
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className='flex-grow sm:flex-none'
            variant='primary'
            onClick={closeAndDoDestroy}
          >
            Destroy
          </Button>
          <Button
            className='flex-grow sm:flex-none'
            variant='link'
            onClick={closeModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
