import * as React from 'react'
import {Formik, Form, useFormikContext} from 'formik'
import { useMutation } from 'react-query'

import {
  FocusView,
  FocusHeader,
  HeadingGroup,
  Title,
  FocusBody,
  FocusCloseButton,
  FocusClose,
  FocusActions,
  ScrollPanel
} from '@toasttab/buffet-pui-config-templates'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import {
  RepoNameField,
  ServiceNameField,
  DeploymentNumberField,
  RevisionField
} from './InputFields'
import { destroyAction, DestroyValues } from '../app/queries'
import { validationSchema } from './FormValidationSchema'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {useState} from "react";
import {DestroyConfirmationModal} from "./DestroyConfirmationModal";


export const DestroyForm = () => {
  const { showErrorSnackBar } = useSnackBar()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isOpen, setIsOpen] = useState(false)
  const closeModal = () => setIsOpen(false)
  const [destroyParams, setDestroyParams] = useState({repo_name: searchParams.get('repoName') || '',
              service_name: searchParams.get('serviceName') || '',
              revision: +searchParams.get('revision')!,
              deployment_number: +searchParams.get('deploymentNumber')!,
              force_destroy: false})

  const forceDestroy = (values: DestroyValues) => {
      values.force_destroy = true
      destroyAction(values).then((response) => {
          let actionId = response
          let repoName = values.repo_name
          navigate(`../${repoName}/actions/${actionId}`)
      }).catch(() => showErrorSnackBar("Error when forcing destroy of last active revision"))
  }

  const mutation = useMutation(
    (values: DestroyValues) => destroyAction(values),
    {
      onMutate: (values: DestroyValues) => {
        return values
      },
      onSuccess: (response, variables) => {
        let actionId = response
        let repoName = variables.repo_name || ''
        if (repoName == '') {
          showErrorSnackBar('Could not get repo name')
        }
        navigate(`../${repoName}/actions/${actionId}`)
      },
      onError: (error: Response, destroyParameters) => {
        if (error.status == 409) {
            // This is the specific error code we're using to indicate this is the last active revision
            setDestroyParams(destroyParameters)
            setIsOpen(true)
        }
        else if (error && error.text !== null) {
          error.text().then((value: string) => {
            showErrorSnackBar(value)
          }).catch(() => {
            showErrorSnackBar('Dobby request failed')
          })
        }
        else {
          showErrorSnackBar('Dobby request failed')
        }
      }
    }
  )

  return (
      <>
          <Formik
            initialValues={destroyParams}
            validationSchema={validationSchema}
           onSubmit={(values) => {
             mutation.mutate(values)
           }}>
            <Form id='destroy-action' autoComplete='false'>
              <DestroyFocusView isLoading={mutation.isLoading} />
            </Form>
          </Formik>
          <DestroyConfirmationModal isModalOpen={isOpen} closeModal={closeModal} doDestroy={forceDestroy} destroyParams={destroyParams} />
      </>
  )
}

const DestroyFocusView = ({ isLoading }: { isLoading: boolean }) => {
  const formik = useFormikContext()

  React.useEffect(() => {
    formik.setSubmitting(isLoading)
  }, [isLoading])

  return (
    <FocusView>
      <FocusHeader>
        <HeadingGroup>
          <Title>Destroy a deployment</Title>
        </HeadingGroup>
        <FocusActions>
          <SubmitButton
            className='w-full sm:w-auto'
            form='destroy-action'
            isInProgress={isLoading}
          />
        </FocusActions>
        <FocusClose>
          {/* @ts-ignore */}
          <FocusCloseButton to={-1} />
        </FocusClose>
      </FocusHeader>
      <FocusBody>
        <div className='lg:max-w-3xl pb-2'>
          <ScrollPanel panelId='main'>
            <div className='space-y-4 md:space-y-6'>
              <div className='relative pt-1 pb-1'>
                <div className='type-headline-5 italic'>
                  Note: This form will only destroy deployments in the preproduction environment.
                  <br></br>Active deployments will be both deactivated <strong>and</strong> destroyed.
                </div>
              </div>
              <RepoNameField />
              <ServiceNameField />
              <RevisionField />
              <DeploymentNumberField />
            </div>
          </ScrollPanel>
        </div>
      </FocusBody>
    </FocusView>
  )
}
